import {
	ISSUES_RESET_DATA,
	ISSUES_APPEND_DATA,
	ISSUES_FILTER_CHANGE,
	ISSUES_RELOAD_DATA,
	ISSUES_SELECTED_KEPT_ISSUES,
	ISSUES_SOLD_ITEM
} from "~/actions/types";

const INITIAL_STATE = {
	datalist: null,
	page_type: "hub_item_issues",
	page_no: 1,
	page_size: 20,
	search: "",
	selected_kept_issues: [],
	sold_item: {},
	kept_item_filter: "",
	packaging_issues_filter: ""
};

export const IssuesReducer = (state = INITIAL_STATE, action) => {
	let newState = { ...state, datalist: { ...state.datalist } };

	if (action.type == ISSUES_RELOAD_DATA) {
		newState.datalist = JSON.parse(action.data);
		newState.page_type = action.pageType;
		newState.selected_kept_issues = [];
		newState.page_no = 1;
		newState.sold_item = {};

		return newState;
	}
	if (action.type == ISSUES_RESET_DATA) {
		return INITIAL_STATE;
	}

	if (action.type == ISSUES_FILTER_CHANGE) {
		newState[action.name] = action.value;
		if (action.name != "page_no") newState.page_no = 1;

		return newState;
	}

	if (action.type == ISSUES_APPEND_DATA) {
		let jsondata = JSON.parse(action.data);
		newState.datalist = { ...state.datalist, issuelist: [...state.datalist.issuelist, ...jsondata.issuelist] };

		return newState;
	}

	if (action.type == ISSUES_SELECTED_KEPT_ISSUES) {
		if (newState.selected_kept_issues.indexOf(action.data) == -1) {
			newState.selected_kept_issues = [...state.selected_kept_issues, action.data];
		} else {
			newState.selected_kept_issues = state.selected_kept_issues.filter((issue) => issue !== action.data);
		}
		//.splice(newState.selected_kept_issues.indexOf(action.data), 1);

		return newState;
	}

	if (action.type == ISSUES_SOLD_ITEM) {
		newState.sold_item = {};
		newState.sold_item.item_id = action.id;
		newState.sold_item.item = action.item;
		newState.sold_item.qty = action.qty;
		newState.sold_item.gross_mrp = action.mrp * action.qty;
		return newState;
	}

	return state;
};
