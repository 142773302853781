import { DASHBOARD_GET_DATA } from "~/actions/types";

const DASHBOARD_INITIAL_STATE = {
	datalist: null
};

export const DashboardReducer = (state = DASHBOARD_INITIAL_STATE, action) => {
	let newState = JSON.parse(JSON.stringify(state));

	if (action.type == DASHBOARD_GET_DATA) {
		newState.datalist = action.data;
		return newState;
	}

	return state;
};
