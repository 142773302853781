import { memo, useEffect, useState } from "react";

import IssueSvg from "~/svg/issue.svg";

const Connection = () => {
	const [is_online, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		const showConnectionBar = () => setIsOnline(false);
		const hideConnectionBar = () => setIsOnline(true);

		window.addEventListener("offline", showConnectionBar);
		window.addEventListener("online", hideConnectionBar);

		return () => {
			window.removeEventListener("offline", showConnectionBar);
			window.removeEventListener("online", hideConnectionBar);
		};
	}, []);

	return (
		<>
			{!is_online ? (
				<div className="dangerBg alignCenter" style={{ color: "#fff", fontSize: "13px" }}>
					<IssueSvg
						style={{ width: "16px", height: "16px", display: "inline", fill: "currentColor" }}
						className="w-4 h-4 fill-current mr-1"
					/>
					<span style={{ fontWeight: "500" }}>You are offline! </span> Please check your internet
				</div>
			) : null}
		</>
	);
};

export default memo(Connection);
