import { ANALYTICS_UPDATE_GRAPH, ANALYTICS_CHANGE_PERIOD, ANALYTICS_CHANGE_KRA_PERIOD, ANALYTICS_CHANGE_REPORT } from "~/actions/types";

const INITIAL_STATE = {
	datalist: null,
	sale_chart_period: "date",
	return_chart_period: "date",
	period: 7,
	kra_period: 7,
	start_date: "",
	end_data: "",
	selected_user: ""
};

export const AnalyticsReducer = (state = INITIAL_STATE, action) => {
	let newState = Object.assign({}, state);

	if (action.type === ANALYTICS_UPDATE_GRAPH) {
		// newState.datalist.charts[action.chart_type] = JSON.parse(action.data);
		newState.datalist = { ...state.datalist, charts: { ...state.datalist.charts, [action.chart_type]: JSON.parse(action.data) } };
		if (action.chartType === "sale") newState.sale_chart_period = action.chartPeriod;
		else if (action.chartType === "return") newState.return_chart_period = action.chartPeriod;

		return newState;
	}

	if (action.type === ANALYTICS_CHANGE_PERIOD) {
		newState.period = action.period;
		newState.datalist = JSON.parse(action.data);

		return newState;
	}

	if (action.type === ANALYTICS_CHANGE_KRA_PERIOD) {
		newState.kra_period = action.period;
		newState.datalist = { ...state.datalist, personnelkra: JSON.parse(action.data) };

		return newState;
	}

	if (action.type === ANALYTICS_CHANGE_REPORT) {
		newState[action.name] = action.value;

		return newState;
	}

	return state;
};
