import { COMMON_PKT_ITEM_INFO, COMMON_SET_COURIER_TRACK_DATA } from "~/actions/types";

const INITIAL_STATE = {
	item_info: [],
	track_info: {}
};

export const CommonReducer = (state = INITIAL_STATE, action) => {
	let newState = Object.assign({}, state);

	if (action.type == COMMON_PKT_ITEM_INFO) {
		newState.item_info = JSON.parse(action.data);

		return newState;
	}

	if (action.type === COMMON_SET_COURIER_TRACK_DATA) {
		newState.track_info = JSON.parse(action.data);

		return newState;
	}

	return state;
};
