import { Component } from "react";

import IssueSvg from "~/svg/issue.svg";

// import Button from "@bit/healthmug.admin.button";
export default class RouteErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		this.setState({ hasError: true });
	}

	reload() {
		// show some loading animation.
		if (navigator.onLine) window.location.reload();
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className="alignCenter" style={{ marginTop: "10%" }}>
					<IssueSvg style={{ width: "60px", height: "60px", fill: "currentColor" }} className="brandtextOrange" />
					<h2 style={{ fontSize: "20px", color: "#666" }}>Something went wrong</h2>
					<button onClick={this.reload} primary small className="btn btn-healthmug marginTop20">
						Reload
					</button>
				</div>
			);
		}

		return this.props.children;
	}
}
