import { ProjectUrl } from "~/env";

import {
	LOGIN_UPDATE_HUB,
	LOGIN_RESET_INITIAL_STATE,
	LOGIN_SET_ERROR_LIST,
	LOGIN_CHANGE_LOADER,
	LOGIN_GET_USER_DETAILS,
	LOGIN_ON_CHANGE_LOGIN_DATA
} from "~/actions/types";

/**
 * @description to save the entered value of user id , password
 * @param {string} key name, password
 * @param {string} value respective key values
 */
export const LoginOnChangeLoginData = (key, value) => ({ type: LOGIN_ON_CHANGE_LOGIN_DATA, key, value });

/**
 * @description Function to validate user details
 * @param {string} email user provided email
 * @param {string} password user provided password
 * @returns {Object}
 */
export const LoginValidateUser = (email, password) => {
	const email_validator = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;
	const password_validator = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$&_~.-])[a-zA-Z0-9#!@$&_~.-]{8,}$/;
	const error_list = {};

	if (!email && email === "") {
		error_list["email"] = "Email is required";
	} else if (!!email && !email_validator.test(email)) {
		error_list["email"] = "Invalid Email ID";
	}

	if (password === "") {
		error_list["password"] = "Password is required";
	} else if (password.length < 10) {
		error_list["password"] = "Password cannot be less than 6 characters";
	} else if (!password_validator.test(password)) {
		error_list["password"] =
			"Password should have at least 8 characters, 1 capital letter, 1 small letter, 1 number and 1 special symbol from #!@$&_~-";
	}

	return error_list;
};

/**
 *  @description Function for login user
 */
export const LoginGetUserLogin = () => (dispatch, getState) => {
	const { login_store } = getState();
	const { email, password, error_list } = login_store;

	let validation = dispatch(LoginOnClickValidateLogin(email, password, error_list));

	if (validation) {
		dispatch({ type: LOGIN_CHANGE_LOADER, loader: true });

		fetch(`${ProjectUrl}/home/userlogin`, {
			method: "POST",
			credentials: "include",
			headers: { "Content-Type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify({ email, pwd: password })
		})
			.then((res) => (res.ok ? res.text() : Promise.reject(res)))
			.then((data) => {
				if (data == "true") {
					window.location = `${ProjectUrl}/analytics/dashboard`;
					dispatch({ type: LOGIN_CHANGE_LOADER, loader: false });
				}
			})
			.catch((err) => {
				err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
				dispatch({ type: LOGIN_CHANGE_LOADER, loader: false });
			});
	}
};

/**
 *  @description Function to get user details
 */
export const LoginGetUserDetails = () => (dispatch, getState) => {
	return fetch(`${ProjectUrl}/home/get-user-details`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			if (data.logged_in_success) {
				dispatch({ type: LOGIN_GET_USER_DETAILS, data });
			}
		})
		.catch((err) => {
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
		});
};

/**
 * @description function to delete error in field present in error_list
 * @param {string} field  it is email or password
 */
export const LoginOnFocus = (field) => ({ type: LOGIN_SET_ERROR_LIST, field, mode: "delete" });

/**
 * @description Function to validate login data
 * @param {string} email
 * @param {string} password
 * @param {string} error_list
 */
export const LoginOnClickValidateLogin = (email, password, error_list) => (dispatch) => {
	let is_validated = true;
	let email_validator = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;

	if (!email && email === "") {
		error_list["email"] = "Email is required";
	} else if (!!email && !email_validator.test(email)) {
		error_list["email"] = "Email is not valid";
	}

	if (password === "") {
		error_list["password"] = "Password is required";
	} else if (password.length < 6) {
		error_list["password"] = "Password cannot be less than 6 characters";
	}

	if (Object.keys(error_list).length > 0) is_validated = false;

	dispatch({ type: LOGIN_SET_ERROR_LIST, errors: error_list, mode: "add" });

	return is_validated;
};

/**
 * @description Function to change hub (Delhi-hub | Mumbai-hub..)
 * @param {number} hub_id
 */
export const LoginUpdateHubChange = (hub_id) => (dispatch, getState) => {
	return fetch(`${ProjectUrl}/home/hubchange`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ hub_id })
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			window.location = `${ProjectUrl}/analytics/dashboard`;
			dispatch({ type: LOGIN_UPDATE_HUB, hub_id });
		})
		.catch((err) => {
			err instanceof Error ? alertify.error(err) : err.text().then((data) => alertify.error(data));
		});
};

/**
 *  @description Function to logout user
 */
export const LoginGetLogout = () => (dispatch) => {
	return fetch(`${ProjectUrl}/home/logout`, {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" }
	})
		.then((res) => (res.ok ? dispatch({ type: LOGIN_RESET_INITIAL_STATE }) : Promise.reject(res)))
		.catch((err) => {
			err instanceof Error ? alertify.error(err.message) : err.text().then((data) => alertify.error(data));
		});
};
