import {
	COMMON_SET_SHOW_LOADER,
	CANCELLED_RETURNS_SEARCH,
	CANCELLED_RETURNS_RELOAD_DATA,
	CANCELLED_RETURNS_APPEND_DATA,
	CANCELLED_RETURNS_PACKET_SELECT,
	CANCELLED_RETURNS_FILTER_CHANGE,
	CANCELLED_RETURNS_SCAN_AWB_ITEM,
	CANCELLED_RETURNS_REMOVE_PACKETS,
	CANCELLED_RETURNS_CHECKED_TO_KEEP,
	CANCELLED_RETURNS_AWB_ITEM_CHANGE,
	CANCELLED_RETURNS_ISSUE_INFO_TICK,
	CANCELLED_RETURNS_REMOVE_AWB_ITEM,
	CANCELLED_RETURNS_SELECTED_TICKET,
	CANCELLED_RETURNS_SET_LOST_REASON,
	CANCELLED_RETURNS_SCAN_PACKET_ITEM,
	CANCELLED_RETURNS_CLEAR_ISSUE_INFO,
	CANCELLED_RETURNS_ISSUE_INFO_UPDATE,
	CANCELLED_RETURNS_RESET_DATA
} from "~/actions/types";

const INITIAL_STATE = {
	filter: "",
	page_no: 1,
	search: "",
	sort_by: "",
	page_size: 100,
	selected_ticket: "",
	page_type: "rto_transit",
	checked_keep_items: false,
	// awb: [],
	item_info: [],
	awb_info: [],
	track_info: [],
	issue_info: [],
	packet_info: [],
	notfound_awbs: [],
	notfound_packets: [],
	datalist: null,
	show_loader: false,
	lost_reason: ""
};

export const CancelledReturnsReducer = (state = INITIAL_STATE, action) => {
	let newState = Object.assign({}, state);

	if (action.type === CANCELLED_RETURNS_RELOAD_DATA) {
		newState.datalist = JSON.parse(action.data);
		newState.page_type = action.pageType;
		newState.page_no = 1;
		newState.selected_ticket = "";
		newState.checked_keep_items = false;
		newState.awb_info = [];
		newState.track_info = [];
		newState.packet_info = [];
		newState.notfound_packets = [];
		newState.notfound_awbs = [];
		newState.show_loader = false;
		newState.lost_reason = "";

		return newState;
	}
	if (action.type == CANCELLED_RETURNS_RESET_DATA) {
		return INITIAL_STATE;
	}

	if (action.type === CANCELLED_RETURNS_APPEND_DATA) {
		newState.datalist = JSON.parse(JSON.stringify(state.datalist));
		const fetchedData = JSON.parse(action.data);
		if (newState.page_type === "return_receive_packets" || newState.page_type === "cancelled_packets") {
			newState.datalist.packetlist = newState.datalist.packetlist.concat(fetchedData.packetlist);
		} else {
			newState.datalist.awblist = newState.datalist.awblist.concat(fetchedData.awblist);
		}
		newState.page_no += 1;

		return newState;
	}

	if (action.type === CANCELLED_RETURNS_SEARCH) {
		newState.search = action.data;

		return newState;
	}

	if (action.type === CANCELLED_RETURNS_FILTER_CHANGE) {
		newState.filter = action.data;

		return newState;
	}

	if (action.type === CANCELLED_RETURNS_CLEAR_ISSUE_INFO) {
		newState.issue_info = [];

		return newState;
	}

	if (action.type === CANCELLED_RETURNS_AWB_ITEM_CHANGE) {
		const index = newState.awb_info.indexOf(action.data);
		if (index == -1) newState.awb_info = [...newState.awb_info, action.data];
		else newState.awb_info = newState.awb_info.filter((item) => item !== action.data);

		return newState;
	}

	if (action.type === CANCELLED_RETURNS_SCAN_AWB_ITEM) {
		const matchedAWBId = state.datalist.awblist.filter((item) => item.awb_info.awb_id === action.data);
		if (matchedAWBId.length > 0) {
			if (state.awb_info.indexOf(action.data) === -1) newState.awb_info = [action.data, ...state.awb_info];
		} else {
			if (state.notfound_awbs.indexOf(action.data) === -1) newState.notfound_awbs = [action.data, ...state.notfound_awbs];
		}

		return newState;
	}

	if (action.type === CANCELLED_RETURNS_REMOVE_AWB_ITEM) {
		if (action.removeAWB === 1) newState.awb_info = newState.awb_info.filter((item) => item != action.data);
		else if (action.removeAWB === 2) newState.notfound_awbs = newState.notfound_awbs.filter((item) => item != action.data);

		return newState;
	}

	if (action.type === CANCELLED_RETURNS_PACKET_SELECT) {
		// newState.packet_info = JSON.parse(JSON.stringify(state.packet_info));
		// if (newState.packet_info.indexOf(action.data) < 0) newState.packet_info.push(action.data);
		// else newState.packet_info.splice(newState.packet_info.indexOf(action.data), 1);
		if (state.packet_info.indexOf(action.data) < 0) newState.packet_info = [...newState.packet_info, action.data];
		else newState.packet_info = newState.packet_info.filter((item) => item !== action.data);

		return newState;
	}

	if (action.type === CANCELLED_RETURNS_SCAN_PACKET_ITEM) {
		if (state.datalist.packetlist.filter((item) => item.packet_id === action.data).length > 0) {
			if (state.packet_info.indexOf(action.data) === -1) newState.packet_info = [action.data, ...state.packet_info];
		} else {
			if (state.notfound_packets.indexOf(action.data) === -1) newState.notfound_packets = [action.data, ...state.notfound_packets];
		}

		return newState;
	}

	if (action.type === CANCELLED_RETURNS_REMOVE_PACKETS) {
		// newState = JSON.parse(JSON.stringify(state));
		if (action.removeFrom === 1) newState.packet_info = newState.packet_info.filter((item) => item !== action.data);
		//.splice(newState.packet_info.indexOf(action.data), 1);
		else if (action.removeFrom === 2) newState.notfound_packets = newState.notfound_packets.filter((item) => item !== action.data); //.splice(newState.notfound_packets.indexOf(action.data), 1);

		return newState;
	}

	if (action.type === CANCELLED_RETURNS_ISSUE_INFO_TICK) {
		// newState.issue_info = JSON.parse(JSON.stringify(state.issue_info));
		const issueItem = state.issue_info.filter((item) => item.order_item_id === action.id);
		if (issueItem.length === 0) {
			newState.issue_info = [
				...newState.issue_info,
				{ order_item_id: action.id, order_packet_id: action.packetId, reason: 0, text: "", qty: 0 }
			];
		} else {
			newState.issue_info = newState.issue_info.filter((item) => item.order_item_id !== action.id);
		}
		// if (issueItem.length === 0) newState.issue_info.push({ order_item_id: action.id, order_packet_id: action.packet_id, reason: 0, text: "", qty: 0 });
		// else newState.issue_info.splice(newState.issue_info.indexOf(issueItem[0]), 1);

		return newState;
	}

	if (action.type === CANCELLED_RETURNS_ISSUE_INFO_UPDATE) {
		newState.issue_info = newState.issue_info.map((item) =>
			item.order_item_id === action.id ? { ...item, qty: action.qty, text: action.text, reason: action.reason } : item
		);

		return newState;
	}

	if (action.type === CANCELLED_RETURNS_CHECKED_TO_KEEP) {
		newState.checked_keep_items = !newState.checked_keep_items;

		return newState;
	}

	if (action.type === CANCELLED_RETURNS_SELECTED_TICKET) {
		newState.selected_ticket = action.data;

		return newState;
	}

	if (action.type === COMMON_SET_SHOW_LOADER) {
		newState.show_loader = action.show_loader;

		return newState;
	}

	if (action.type === CANCELLED_RETURNS_SET_LOST_REASON) {
		newState.lost_reason = action.lost_reason;

		return newState;
	}

	return state;
};
