import { combineReducers } from "redux";

import { HubReducer } from "~/reducers/hub-reducer";
import { StoresReducer } from "~/reducers/stores-reducer";
import { IssuesReducer } from "~/reducers/issues-reducer";
import { AnalyticsReducer } from "~/reducers/analytics-reducer";
import { LoginReducer } from "~/reducers/login-reducer";
import { OrdersReducer } from "~/reducers/orders-reducer";
import { CommonReducer } from "~/reducers/common-reducer";
import { PacketInfoReducer } from "~/reducers/packet-info-reducer";
import { DashboardReducer } from "~/reducers/dashboard-reducer";
import { OutgoingReturnsReducer } from "~/reducers/outgoing-returns-reducer";
import { CancelledReturnsReducer } from "~/reducers/cancelled-returns-reducer";
import { PackagingDashboardReducer } from "~/reducers/packaging-dashboard-reducer";
import { IncomingOrdersReducer } from "~/reducers/incoming-orders-reducer";

const rootReducer = combineReducers({
	hub_store: HubReducer,
	stores: StoresReducer,
	login_store: LoginReducer,
	common_store: CommonReducer,
	orders_store: OrdersReducer,
	issues_store: IssuesReducer,
	analytics_store: AnalyticsReducer,
	dashboard_store: DashboardReducer,
	packet_info_store: PacketInfoReducer,
	incoming_orders_store: IncomingOrdersReducer,
	outgoing_returns_store: OutgoingReturnsReducer,
	cancelled_returns_store: CancelledReturnsReducer,
	packaging_dashboard_store: PackagingDashboardReducer
});

export default rootReducer;
