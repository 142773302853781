import { useState } from "react";

/**
 * @description Custom Loader hook.
 * @param {boolean} initial_state
 * */
export const useHealthmugLoader = (initial_state) => {
	const [loader, setLoader] = useState(initial_state);

	const startLoader = () => setLoader(true);
	const endLoader = () => setLoader(false);

	return [loader, startLoader, endLoader];
};
