import {
	LOGIN_UPDATE_HUB,
	LOGIN_RESET_INITIAL_STATE,
	LOGIN_CHANGE_LOADER,
	LOGIN_SET_ERROR_LIST,
	LOGIN_GET_USER_DETAILS,
	LOGIN_CHANGE_LOGIN_ERROR,
	LOGIN_ON_CHANGE_LOGIN_DATA,
	LOGIN_RESET_PASSWORD_SCREEN,
	LOGIN_ON_CHANGE_PASSWORD_SCREEN
} from "~/actions/types";

const INITIAL_STATE = {
	email: "",
	password: "",
	error: false,
	error_message: "",
	loader: false,
	hub_id: "1",
	error_list: {},
	datalist: {},

	// change password screen
	current_password: "",
	new_password: "",
	confirm_password: ""
};

export const LoginReducer = (state = INITIAL_STATE, action) => {
	let newState = Object.assign({}, state);

	if (action.type === LOGIN_ON_CHANGE_LOGIN_DATA) {
		newState[action.key] = action.value;
		return newState;
	}

	if (action.type === LOGIN_CHANGE_LOGIN_ERROR) {
		newState.error = action.error;
		return newState;
	}

	if (action.type === LOGIN_RESET_INITIAL_STATE) {
		return INITIAL_STATE;
	}

	if (action.type === LOGIN_CHANGE_LOADER) {
		newState.loader = action.loader;
		return newState;
	}

	if (action.type === LOGIN_GET_USER_DETAILS) {
		newState.datalist = action.data;
		newState.hub_id = action.data.hub_id;

		return newState;
	}

	if (action.type === LOGIN_UPDATE_HUB) {
		newState.hub_id = action.hub_id;
		return newState;
	}

	if (action.type === LOGIN_SET_ERROR_LIST) {
		if (action.mode == "add") {
			newState.error_list = { ...action.errors };
		} else if (action.mode == "delete") {
			const new_error_list = newState.error_list;

			delete new_error_list[action.field];
			newState.error_list = { ...new_error_list };
		}

		return newState;
	}

	// for change password screen
	if (action.type === LOGIN_RESET_PASSWORD_SCREEN) {
		newState = { ...newState, current_password: "", new_password: "", confirm_password: "" };
		return newState;
	}

	if (action.type === LOGIN_ON_CHANGE_PASSWORD_SCREEN) {
		newState[action.name] = action.value;

		return newState;
	}

	return state;
};
// export default LoginReducer;
