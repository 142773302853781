import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "wouter";

import { ProjectUrl } from "~/env";

import EyeSvg from "~/svg/eye.svg";
import HomeSvg from "~/svg/home.svg";
import MenuSvg from "~/svg/menu.svg";
import CloseSvg from "~/svg/close.svg";
import IssueSvg from "~/svg/issue.svg";
import UsersSvg from "~/svg/users.svg";
import BannedSvg from "~/svg/banned.svg";
import SettingsSvg from "~/svg/settings.svg";
import GraphLineSVg from "~/svg/graph-line.svg";
import ArrowSideSvg from "~/svg/arrow-side.svg";
import DashSquareSvg from "~/svg/dash-square.svg";
import InfoFilledSvg from "~/svg/info-filled.svg";
import SpeedometerSvg from "~/svg/speedometer.svg";
import ShoppingBagSvg from "~/svg/shopping-bag.svg";
import ShoppingCartSvg from "~/svg/shopping-cart.svg";

import { LoginGetLogout, LoginUpdateHubChange } from "~/actions/action-login";

const LeftMenu = (props) => {
	const [show_menu, setShowMenu] = useState(false);

	const toggleLeftMenu = () => {
		setShowMenu(!show_menu);
	};

	return (
		<aside id="left-sidebar-nav" className={show_menu ? "openNav" : ""}>
			<a className="toggleBt" onClick={toggleLeftMenu}>
				{show_menu ? <CloseSvg /> : <MenuSvg />}
			</a>
			<div className="sideBarHeader">
				<div className="sideBarLogo">
					<img src="https://static.oxinis.com/hub/image/layout/hub-logo.png" /> <span>{props.datalist.hub_name}</span>
				</div>

				<Settings {...props} />
			</div>

			<MenuItems {...props} />
		</aside>
	);
};

const Settings = (props) => {
	const [show_drop_down, setShowDropDown] = useState(false);

	useEffect(() => {
		// window event will only be added at mounting phase.
		const handler = (e) => !e.target.matches(".dropBtn") && setShowDropDown(false); // Sets showDropDown property to false when the clicked element does't have CSS class "dropBtn"
		window.addEventListener("click", handler);

		return () => {
			window.removeEventListener("click", handler);
		};
	}, []);

	const userLogout = () => {
		props
			.Login_Get_Logout()
			.then(() => (window.location.href = ProjectUrl))
			.catch((err) => null);
	};

	return (
		<div className="leftMenuSellerInfo">
			<div className="dropdownMenu">
				<button onClick={() => setShowDropDown((prev) => !prev)} className="dropBtn">
					<SettingsSvg />
					<span>Settings</span>
					<i className="fa fa-sort-down"></i>
				</button>
				<div id="myDropdown" className={`dropdownContent ${show_drop_down ? "show" : ""}`}>
					<ul>
						<li>
							<h3>
								<HomeSvg />
								{props.datalist.hub_name}
							</h3>
						</li>
						<li style={{ padding: "0px 10px" }}>
							{!!props.datalist.associated_hubs
								? props.datalist.associated_hubs.length > 0 && (
										<select
											name="hub_id"
											className="form-control dropBtn"
											style={{ textAlign: "left", border: "1px solid #b7b7b7" }}
											value={props.hub_id}
											onChange={(e) => props.Login_Update_Hub_Change(e.target.value)}
										>
											{props.datalist.associated_hubs.map((hub) => (
												<option value={hub.id} key={hub.id}>
													{hub.name}
												</option>
											))}
										</select>
								  )
								: null}
						</li>

						<li>
							<a>{props.datalist.user_name}</a>
						</li>
						<li>
							<Link href="/hub/change-password">Change Password</Link>
						</li>
						<li>
							<Link href="/hub/contact-info">Contact Details</Link>
						</li>
						<li>
							<Link href="/hub/hub-info">Hub Details</Link>
						</li>
						<li>
							<Link href="/hub/hub-apps">Hub Apps</Link>
						</li>
						<li>
							<a onClick={userLogout}>Logout</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

const MenuItems = (props) => {
	const [location] = useLocation();

	return (
		<div>
			<ul id="slide-out" className="nav nav-pills nav-stacked">
				<li className="userInitialWrap">
					<a href="#" className="loginUser">
						<span className="navLinkIcon">
							<span>{props.datalist.short_user_name}</span>
						</span>
						<span className="linkTitleWrap">
							<span className="linkTitle">{props.datalist.user_name}</span>
						</span>
					</a>
				</li>
				<li>
					<a className="dcInfo">
						<span className="navLinkIcon">
							{props.datalist.permissions != null && props.datalist.permissions.includes("Hub View") ? <EyeSvg /> : null}
							{props.datalist.hub_short_name}
						</span>
					</a>
				</li>
				<MenuItem title="Dashboard" href="/analytics/dashboard" active={location === "/analytics/dashboard"} icon={<SpeedometerSvg />} />
				<MenuItem
					title="Incoming Orders"
					href="/orders/incoming-orders"
					active={location === "/orders/incoming-orders"}
					icon={<ArrowSideSvg style={{ width: "18px", height: "18px" }} />}
				/>
				<MenuItem title="Orders" href="/orders/orders" active={location === "/orders/orders"} icon={<ShoppingCartSvg />} />
				<MenuItem
					title="Outgoing Returns"
					href="/orders/outgoing-returns"
					active={location === "/orders/outgoing-returns"}
					icon={<ArrowSideSvg style={{ transform: "rotate(180deg)", width: "18px", height: "18px" }} />}
				/>
				<MenuItem
					title="Cancelled/Returns"
					href="/orders/cancelled-returns-orders"
					active={location === "/orders/cancelled-returns-orders"}
					icon={<BannedSvg />}
				/>
				<MenuItem
					title="Packet Info"
					href="/orders/packet-info"
					active={location === "/orders/packet-info"}
					icon={<InfoFilledSvg style={{ width: "18px", height: "18px" }} />}
				/>
				{props.datalist.permissions != null &&
				(props.datalist.permissions.includes("View Issue") || props.datalist.permissions.includes("Hub View")) ? (
					<MenuItem title="Issues" href="/issues/issues" active={location === "/issues/issues"} icon={<IssueSvg />} />
				) : null}
				<MenuItem
					title="Stores"
					href="/stores/stores"
					active={location === "/stores/stores"}
					icon={<ShoppingBagSvg className="text-white fill-current h-5 w-5" />}
				/>
				<MenuItem
					title="Sellers"
					href="/hub/sellers"
					active={location === "/hub/sellers"}
					icon={<UsersSvg style={{ width: "24px", height: "24px" }} />}
				/>
				{props.datalist.permissions != null &&
				(props.datalist.permissions.includes("Hub Analytics View") || props.datalist.permissions.includes("Hub View")) ? (
					<>
						<MenuItem
							title="Analytics"
							href="/analytics/analytics"
							active={location === "/analytics/analytics"}
							icon={<GraphLineSVg />}
						/>
						<MenuItem
							title="Packaging Dashboard"
							href="/analytics/packaging-dashboard"
							active={location === "/analytics/packaging-dashboard"}
							icon={<DashSquareSvg />}
						/>
					</>
				) : null}
			</ul>
		</div>
	);
};

const MenuItem = ({ title, href, active, icon }) => {
	return (
		<li>
			<Link href={href}>
				<a className={active ? "active" : null}>
					<span className="navLinkIcon">{icon}</span>
					<span className="linkTitleWrap">
						<span className="linkTitle">{title}</span>
					</span>
				</a>
			</Link>
		</li>
	);
};

const mapStateToProps = (state) => ({
	hub_id: state.login_store.hub_id,
	datalist: state.login_store.datalist
});
const mapDispatchToProps = (dispatch) => ({
	Login_Get_Logout: () => dispatch(LoginGetLogout()),
	Login_Update_Hub_Change: (hub_id) => dispatch(LoginUpdateHubChange(hub_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);
