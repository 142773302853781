import AppRoutes from "~/routes.jsx";

import AppContainer from "~/components/common/app-container.jsx";

const App = () => {
	return (
		<div id="main" className="closeMenu">
			<div className="sm:pl-16 pl-4 pr-4 sm:pr-5">
				<AppContainer>
					<AppRoutes />
				</AppContainer>
			</div>
		</div>
	);
};

export default App;
