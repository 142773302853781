import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import rootReducer from "~/reducers/index";

const configureAppStore = (preloadedState) => {
	const middlewares = [thunk];

	const middlewareEnhancer = applyMiddleware(...middlewares);

	const enhancers = [middlewareEnhancer];

	let composeEnhancers = compose;

	// for using redux devtools extension in dev mode
	if (process.env.NODE_ENV !== "production") {
		composeEnhancers =
			typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
				? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
						// Specify extension’s options like name, actionsDenylist, actionsCreators, serialize...
				  })
				: compose;
	}

	const composedEnhancers = composeEnhancers(...enhancers);

	const store = createStore(rootReducer, preloadedState, composedEnhancers);

	return store;
};

export const store = configureAppStore();
