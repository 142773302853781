const Footer = () => {
	return (
		<footer>
			<img src="https://static.oxinis.com/hub/image/layout/logo-healthmug.png" />
			<span>| Copyright© {new Date().getFullYear()}. All rights reserved.</span>
		</footer>
	);
};

export default Footer;
