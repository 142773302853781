//Action-Login types
export const LOGIN_UPDATE_HUB = "LOGIN_UPDATE_HUB";
export const LOGIN_RESET_INITIAL_STATE = "LOGIN_RESET_INITIAL_STATE";
export const LOGIN_CHANGE_LOADER = "LOGIN_CHANGE_LOADER";
export const LOGIN_SET_ERROR_LIST = "LOGIN_SET_ERROR_LIST";
export const LOGIN_GET_USER_DETAILS = "LOGIN_GET_USER_DETAILS";
export const LOGIN_CHANGE_LOGIN_ERROR = "LOGIN_CHANGE_LOGIN_ERROR";
export const LOGIN_ON_CHANGE_LOGIN_DATA = "LOGIN_ON_CHANGE_LOGIN_DATA";
export const LOGIN_RESET_PASSWORD_SCREEN = "LOGIN_RESET_PASSWORD_SCREEN";
export const LOGIN_ON_CHANGE_PASSWORD_SCREEN = "LOGIN_ON_CHANGE_PASSWORD_SCREEN";

//Dashboard types
export const DASHBOARD_GET_DATA = "DASHBOARD_GET_DATA";

//*** FOR COMMON REDUCER ***
export const COMMON_PKT_ITEM_INFO = "COMMON_PKT_ITEM_INFO";
export const COMMON_SET_SHOW_LOADER = "COMMON_SET_SHOW_LOADER";
export const COMMON_SET_COURIER_TRACK_DATA = "COMMON_SET_COURIER_TRACK_DATA";

//*** FOR HUB REDUCER ***
export const HUB_INFO_INIT_DATA = "HUB_INFO_INIT_DATA";
export const HUB_SELLER_RELOAD_DATA = "HUB_SELLER_RELOAD_DATA";
export const HUB_SELLER_APPEND_DATA = "HUB_SELLER_APPEND_DATA";
export const HUB_SELLER_SEARCH_CHANGE = "HUB_SELLER_SEARCH_CHANGE";
export const HUB_CONTACT_INFO_INIT_DATA = "HUB_CONTACT_INFO_INIT_DATA";

//*** FOR STORE REDUCER ***
export const STORES_SEARCH = "STORES_SEARCH";
export const STORES_RESET_DATA = "STORES_RESET_DATA";
export const STORES_ITEM_INFO = "STORES_ITEM_INFO";
export const STORES_MARK_LOST = "STORES_MARK_LOST";
export const STORES_RELOAD_DATA = "STORES_RELOAD_DATA";
export const STORES_APPEND_DATA = "STORES_APPEND_DATA";
export const STORES_CANCEL_LOST = "STORES_CANCEL_LOST";
export const STORES_PACKET_CHANGE = "STORES_PACKET_CHANGE";
export const STORES_EDIT_DIMENSIONS = "STORES_EDIT_DIMENSIONS";
export const STORES_SET_LOST_REASON = "STORES_SET_LOST_REASON";
export const STORES_CLEAR_DIMENSIONS = "STORES_CLEAR_DIMENSIONS";
export const STORES_SET_VARIANT_DIMENSION = "STORES_SET_VARIANT_DIMENSION";

//*** FOR PACKET INFO REDUCER ***
export const PACKET_INFO_TRACK_ITEM = "PACKET_INFO_TRACK_ITEM";
export const PACKET_INFO_RELOAD_DATA = "PACKET_INFO_RELOAD_DATA";

//*** FOR PACKAGING DASHBOARD REDUCER ***
export const PACKAGING_DASHBOARD_INIT_DATA = "PACKAGING_DASHBOARD_INIT_DATA";
export const PACKAGING_DASHBOARD_LOAD_PACKAGING_DATA = "PACKAGING_DASHBOARD_LOAD_PACKAGING_DATA";
export const PACKAGING_DASHBOARD_LOAD_KRA_GRAPH_DATA = "PACKAGING_DASHBOARD_LOAD_KRA_GRAPH_DATA";

//*** FOR ANALYTICS REDUCER ***
export const ANALYTICS_UPDATE_GRAPH = "ANALYTICS_UPDATE_GRAPH";
export const ANALYTICS_CHANGE_PERIOD = "ANALYTICS_CHANGE_PERIOD";
export const ANALYTICS_CHANGE_REPORT = "ANALYTICS_CHANGE_REPORT";
export const ANALYTICS_CHANGE_KRA_PERIOD = "ANALYTICS_CHANGE_KRA_PERIOD";

//*** FOR ISSUE INFO REDUCER ***
export const ISSUES_SOLD_ITEM = "ISSUES_SOLD_ITEM";
export const ISSUES_RESET_DATA = "ISSUES_RESET_DATA";
export const ISSUES_RELOAD_DATA = "ISSUES_RELOAD_DATA";
export const ISSUES_APPEND_DATA = "ISSUES_APPEND_DATA";
export const ISSUES_FILTER_CHANGE = "ISSUES_FILTER_CHANGE";
export const ISSUES_SELECTED_KEPT_ISSUES = "ISSUES_SELECTED_KEPT_ISSUES";

//*** FOR OUTGOING RETURNS REDUCER ***
export const OUTGOING_RETURNS_SEARCH = "OUTGOING_RETURNS_SEARCH";
export const OUTGOING_RETURNS_ITEM_INFO = "OUTGOING_RETURNS_ITEM_INFO";
export const OUTGOING_RETURNS_RESET_DATA = "OUTGOING_RETURNS_RESET_DATA";
export const OUTGOING_RETURNS_RELOAD_DATA = "OUTGOING_RETURNS_RELOAD_DATA";
export const OUTGOING_RETURNS_APPEND_DATA = "OUTGOING_RETURNS_APPEND_DATA";
export const OUTGOING_RETURNS_FILTER_CHANGE = "OUTGOING_RETURNS_FILTER_CHANGE";
export const OUTGOING_RETURNS_PACKET_SELECT = "OUTGOING_RETURNS_PACKET_SELECT";
export const OUTGOING_RETURNS_REMOVE_PACKETS = "OUTGOING_RETURNS_REMOVE_PACKETS";
export const OUTGOING_RETURNS_AWB_ITEM_CHANGE = "OUTGOING_RETURNS_AWB_ITEM_CHANGE";
export const OUTGOING_RETURNS_RETURN_ITEM_INFO = "OUTGOING_RETURNS_RETURN_ITEM_INFO";
export const OUTGOING_RETURNS_SCAN_PACKET_ITEM = "OUTGOING_RETURNS_SCAN_PACKET_ITEM";
export const OUTGOING_RETURNS_SET_RECEIVER_ARRAY = "OUTGOING_RETURNS_SET_RECEIVER_ARRAY";

//*** FOR CANCELLED RETURNS REDUCER ***
export const CANCELLED_RETURNS_SEARCH = "CANCELLED_RETURNS_SEARCH";
export const CANCELLED_RETURNS_RESET_DATA = "CANCELLED_RETURNS_RESET_DATA";
export const CANCELLED_RETURNS_TRACK_ITEM = "CANCELLED_RETURNS_TRACK_ITEM";
export const CANCELLED_RETURNS_RELOAD_DATA = "CANCELLED_RETURNS_RELOAD_DATA";
export const CANCELLED_RETURNS_APPEND_DATA = "CANCELLED_RETURNS_APPEND_DATA";
export const CANCELLED_RETURNS_PACKET_SELECT = "CANCELLED_RETURNS_PACKET_SELECT";
export const CANCELLED_RETURNS_FILTER_CHANGE = "CANCELLED_RETURNS_FILTER_CHANGE";
export const CANCELLED_RETURNS_SCAN_AWB_ITEM = "CANCELLED_RETURNS_SCAN_AWB_ITEM";
export const CANCELLED_RETURNS_REMOVE_PACKETS = "CANCELLED_RETURNS_REMOVE_PACKETS";
export const CANCELLED_RETURNS_AWB_ITEM_CHANGE = "CANCELLED_RETURNS_AWB_ITEM_CHANGE";
export const CANCELLED_RETURNS_REMOVE_AWB_ITEM = "CANCELLED_RETURNS_REMOVE_AWB_ITEM";
export const CANCELLED_RETURNS_ISSUE_INFO_TICK = "CANCELLED_RETURNS_ISSUE_INFO_TICK";
export const CANCELLED_RETURNS_CHECKED_TO_KEEP = "CANCELLED_RETURNS_CHECKED_TO_KEEP";
export const CANCELLED_RETURNS_SELECTED_TICKET = "CANCELLED_RETURNS_SELECTED_TICKET";
export const CANCELLED_RETURNS_SET_LOST_REASON = "CANCELLED_RETURNS_SET_LOST_REASON";
export const CANCELLED_RETURNS_CLEAR_ISSUE_INFO = "CANCELLED_RETURNS_CLEAR_ISSUE_INFO";
export const CANCELLED_RETURNS_SCAN_PACKET_ITEM = "CANCELLED_RETURNS_SCAN_PACKET_ITEM";
export const CANCELLED_RETURNS_ISSUE_INFO_UPDATE = "CANCELLED_RETURNS_ISSUE_INFO_UPDATE";

//*** FOR INCOMING ORDERS REDUCER ***
export const INCOMING_ORDERS_SEARCH = "INCOMING_ORDERS_SEARCH";
export const INCOMING_ORDERS_RESET_DATA = "INCOMING_ORDERS_RESET_DATA";
export const INCOMING_ORDERS_APPEND_DATA = "INCOMING_ORDERS_APPEND_DATA";
export const INCOMING_ORDERS_RELOAD_DATA = "INCOMING_ORDERS_RELOAD_DATA";
export const INCOMING_ORDERS_FILTER_CHANGE = "INCOMING_ORDERS_FILTER_CHANGE";
export const INCOMING_ORDERS_PACKET_SELECT = "INCOMING_ORDERS_PACKET_SELECT";
export const INCOMING_ORDERS_REMOVE_PACKETS = "INCOMING_ORDERS_REMOVE_PACKETS";
export const INCOMING_ORDERS_EDIT_DIMENSION = "INCOMING_ORDERS_EDIT_DIMENSION";
export const INCOMING_ORDERS_UNRECEIVED_AWBS = "INCOMING_ORDERS_UNRECEIVED_AWBS";
export const INCOMING_ORDERS_AWB_ITEM_SELECT = "INCOMING_ORDERS_AWB_ITEM_SELECT";
export const INCOMING_ORDERS_ISSUE_INFO_TICK = "INCOMING_ORDERS_ISSUE_INFO_TICK";
export const INCOMING_ORDERS_CLEAR_DIMENSION = "INCOMING_ORDERS_CLEAR_DIMENSION";
export const INCOMING_ORDERS_CHECKED_TO_KEEP = "INCOMING_ORDERS_CHECKED_TO_KEEP";
export const INCOMING_ORDERS_CLEAR_ISSUE_INFO = "INCOMING_ORDERS_CLEAR_ISSUE_INFO";
export const INCOMING_ORDERS_AUTO_RELOAD_DATA = "INCOMING_ORDERS_AUTO_RELOAD_DATA";
export const INCOMING_ORDERS_AWB_WEIGHT_ENTRY = "INCOMING_ORDERS_AWB_WEIGHT_ENTRY";
export const INCOMING_ORDERS_SCAN_PACKET_ITEM = "INCOMING_ORDERS_SCAN_PACKET_ITEM";
export const INCOMING_ORDERS_ISSUE_INFO_UPDATE = "INCOMING_ORDERS_ISSUE_INFO_UPDATE";
export const INCOMING_ORDERS_DELAYED_AWB_SELECT = "INCOMING_ORDERS_DELAYED_AWB_SELECT";
export const INCOMING_ORDERS_PENDING_PACKET_SELECT = "INCOMING_ORDERS_PENDING_PACKET_SELECT";
export const INCOMING_ORDERS_SET_VARIANT_DIMENSION = "INCOMING_ORDERS_SET_VARIANT_DIMENSION";

//*** FOR ORDERS REDUCER ***
export const ORDERS_SEARCH = "ORDERS_SEARCH";
export const ORDERS_BOX_SIZE = "ORDERS_BOX_SIZE";
export const ORDERS_ITEM_INFO = "ORDERS_ITEM_INFO";
export const ORDERS_RESET_DATA = "ORDERS_RESET_DATA";
export const ORDERS_EDIT_ORDER = "ORDERS_EDIT_ORDER";
export const ORDERS_RELOAD_DATA = "ORDERS_RELOAD_DATA";
export const ORDERS_APPEND_DATA = "ORDERS_APPEND_DATA";
export const ORDERS_ORDER_CHANGE = "ORDERS_ORDER_CHANGE";
export const ORDERS_PACKET_CHANGE = "ORDERS_PACKET_CHANGE";
export const ORDERS_SCAN_AWB_ITEM = "ORDERS_SCAN_AWB_ITEM";
export const ORDERS_REMOVE_ORDERS = "ORDERS_REMOVE_ORDERS";
export const ORDERS_FILTER_CHANGE = "ORDERS_FILTER_CHANGE";
export const ORDERS_ISSUE_INFO_TICK = "ORDERS_ISSUE_INFO_TICK";
export const ORDERS_AWB_ITEM_CHANGE = "ORDERS_AWB_ITEM_CHANGE";
export const ORDERS_SCAN_ORDER_ITEM = "ORDERS_SCAN_ORDER_ITEM";
export const ORDERS_CHECKED_TO_KEEP = "ORDERS_CHECKED_TO_KEEP";
export const ORDERS_VIEW_PICKUP_DATA = "ORDERS_VIEW_PICKUP_DATA";
export const ORDERS_ISSUE_INFO_UPDATE = "ORDERS_ISSUE_INFO_UPDATE";
export const ORDERS_SET_PARCEL_DIMENSION = "ORDERS_SET_PARCEL_DIMENSION";
export const ORDERS_FILTER__WEBSITE_CHANGE = "ORDERS_FILTER__WEBSITE_CHANGE";
