import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import MicroModal from "micromodal";

import "~/styles/healthmug-hub.scss";

import { store } from "~/store";

import App from "~/app.jsx";

MicroModal.init();

const page_root = document.getElementById("pageRoot");
const root = createRoot(page_root);

root.render(
	<Provider store={store}>
		<App />
	</Provider>
);
