import {
	OUTGOING_RETURNS_SEARCH,
	OUTGOING_RETURNS_ITEM_INFO,
	OUTGOING_RETURNS_RESET_DATA,
	OUTGOING_RETURNS_RELOAD_DATA,
	OUTGOING_RETURNS_APPEND_DATA,
	OUTGOING_RETURNS_PACKET_SELECT,
	OUTGOING_RETURNS_FILTER_CHANGE,
	OUTGOING_RETURNS_REMOVE_PACKETS,
	OUTGOING_RETURNS_AWB_ITEM_CHANGE,
	OUTGOING_RETURNS_RETURN_ITEM_INFO,
	OUTGOING_RETURNS_SCAN_PACKET_ITEM,
	OUTGOING_RETURNS_SET_RECEIVER_ARRAY,
} from "~/actions/types";

const INITIAL_STATE = {
	datalist: null,
	page_type: "to_return",
	page_no: 1,
	page_size: 100,
	search: "",
	sort_by: "",
	filter: "",
	item_info: [],
	return_item_info: [],
	awb_info: [],
	packet_info: [],
	notfound_packets: [],
	other_seller_packets: [],
	receiver_array: []
};

export const OutgoingReturnsReducer = (state = INITIAL_STATE, action) => {
	let newState = Object.assign({}, state);

	if (action.type === OUTGOING_RETURNS_RELOAD_DATA) {
		newState.datalist = JSON.parse(action.data);
		newState.page_type = action.pageType;
		newState.awb_info = [];
		newState.packet_info = [];
		newState.notfound_packets = [];
		newState.other_seller_packets = [];
		newState.receiver_array = [];
		newState.page_no = 1;

		return newState;
	}

	if(action.type === OUTGOING_RETURNS_RESET_DATA) {
       return INITIAL_STATE;
	}

	if (action.type === OUTGOING_RETURNS_APPEND_DATA) {
		newState.datalist = JSON.parse(JSON.stringify(state.datalist));
		newState.datalist.orderlist = newState.datalist.orderlist.concat(JSON.parse(action.data).orderlist);
		newState.page_no += 1;

		return newState;
	}

	if (action.type === OUTGOING_RETURNS_SEARCH) {
		newState.search = action.data;

		return newState;
	}

	if (action.type === OUTGOING_RETURNS_FILTER_CHANGE) {
		newState.filter = action.data;

		return newState;
	}

	// if (action.type === OR_ITEM_INFO) {
	// 	newState.item_info = JSON.parse(action.data);
	// 	return newState;
	// }

	if (action.type === OUTGOING_RETURNS_RETURN_ITEM_INFO) {
		newState.return_item_info = JSON.parse(action.data);

		return newState;
	}

	if (action.type === OUTGOING_RETURNS_AWB_ITEM_CHANGE) {
		if (newState.awb_info.indexOf(action.data) === -1) {
			newState.awb_info = [...newState.awb_info, action.data];
			if (newState.datalist.orderlist.filter((awb) => awb.awb_id === action.data)[0].courier_partner_id === 7) {
				newState.receiver_array = [...newState.receiver_array, { awb_id: action.data, receiver: { name: "" } }];
			}
		} else {
			newState.awb_info = newState.awb_info.filter((item) => item != action.data);
			newState.receiver_array = newState.receiver_array.filter((item) => item.awb_id != action.data);
		}

		return newState;
	}

	if (action.type === OUTGOING_RETURNS_PACKET_SELECT) {
		if (action.data == "") {
			newState.packet_info = [];
		} else {
			if (newState.packet_info.indexOf(action.data) === -1) newState.packet_info = [action.data, ...newState.packet_info];
			else newState.packet_info = newState.packet_info.filter((item) => item != action.data);
		}

		return newState;
	}

	if (action.type === OUTGOING_RETURNS_SCAN_PACKET_ITEM) {
		const orders = newState.datalist.packetlist.filter((item) => item.packet_id === action.data);
		if (orders.length > 0) {
			if (
				newState.packet_info.length > 0 &&
				orders[0].seller_id != newState.datalist.packetlist.filter((item) => item.packet_id === newState.packet_info[0])[0].seller_id
			) {
				newState.other_seller_packets = [...newState.other_seller_packets, action.data];
			} else if (newState.packet_info.indexOf(action.data) === -1) {
				newState.packet_info = [action.data, ...newState.packet_info];
			}
		} else {
			if (newState.notfound_packets.indexOf(action.data) === -1) newState.notfound_packets = [action.data, ...newState.notfound_packets];
		}

		return newState;
	}

	if (action.type === OUTGOING_RETURNS_REMOVE_PACKETS) {
		if (action.removeFrom === 1) newState.packet_info = newState.packet_info.filter((item) => item != action.data);
		else if (action.removeFrom === 2) newState.notfound_packets = newState.notfound_packets.filter((item) => item != action.data);
		else if (action.removeFrom === 3) newState.other_seller_packets = newState.other_seller_packets.filter((item) => item != action.data);

		return newState;
	}

	if (action.type === OUTGOING_RETURNS_SET_RECEIVER_ARRAY) {
		// newState.receiver_array = newState.receiver_array.filter((receiver) => receiver.awb_id === action.awbId)[0].receiver[action.field] = action.value;
		newState.receiver_array = newState.receiver_array.map((item) =>
			item.awb_id === action.awbId ? { ...item, receiver: { ...item.receiver, [action.field]: action.value } } : item
		);

		return newState;
	}

	return state;
};
