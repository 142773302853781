import {
	HUB_INFO_INIT_DATA,
	HUB_SELLER_RELOAD_DATA,
	HUB_SELLER_APPEND_DATA,
	HUB_SELLER_SEARCH_CHANGE,
	HUB_CONTACT_INFO_INIT_DATA
} from "~/actions/types";

const INITIAL_STATE = {
	datalist: {},
	pageno: 1,
	pagesize: 20,
	search: ""
};

export const HubReducer = (state = INITIAL_STATE, action) => {
	let newState = { ...state, datalist: { ...state.datalist } };

	if (action.type == HUB_CONTACT_INFO_INIT_DATA) {
		newState.datalist = JSON.parse(action.data);

		return newState;
	}

	if (action.type == HUB_INFO_INIT_DATA) {
		newState.datalist = JSON.parse(action.data);

		return newState;
	}

	if (action.type == HUB_SELLER_RELOAD_DATA) {
		newState.datalist = JSON.parse(action.data);
		newState.pageno = 1;

		return newState;
	}

	if (action.type == HUB_SELLER_APPEND_DATA) {
		let jsondata = JSON.parse(action.data).sellers;
		newState.datalist.sellers = [...newState.datalist.sellers, ...jsondata];
		newState.pageno += 1;

		return newState;
	}

	if (action.type == HUB_SELLER_SEARCH_CHANGE) {
		newState.search = action.value;

		return newState;
	}

	return state;
};
