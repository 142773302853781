import { PACKAGING_DASHBOARD_LOAD_PACKAGING_DATA, PACKAGING_DASHBOARD_LOAD_KRA_GRAPH_DATA, PACKAGING_DASHBOARD_INIT_DATA } from "~/actions/types";

const INITIAL_STATE = {
	datalist: null,
	period: 7,
	graph_period: 1
};

export const PackagingDashboardReducer = (state = INITIAL_STATE, action) => {
	let newState = Object.assign({}, state);

	if (action.type === PACKAGING_DASHBOARD_INIT_DATA) {
		newState.datalist = JSON.parse(action.data);

		return newState;
	}

	if (action.type === PACKAGING_DASHBOARD_LOAD_PACKAGING_DATA) {
		const jsonData = JSON.parse(action.data);
		newState.datalist = {
			...state.datalist,
			weight_volume: {
				...state.datalist.weight_volume,
				packets_weight: jsonData.packets_weight,
				packets_weight_vloume: jsonData.packets_weight_vloume
			}
		};
		newState.period = action.period;

		return newState;
	}

	if (action.type === PACKAGING_DASHBOARD_LOAD_KRA_GRAPH_DATA) {
		newState.datalist = { ...state.datalist, kra_graph: JSON.parse(action.data) };
		newState.graph_period = action.period;

		return newState;
	}

	return state;
};
