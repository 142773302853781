import {
	COMMON_SET_SHOW_LOADER,
	INCOMING_ORDERS_SEARCH,
	INCOMING_ORDERS_RESET_DATA,
	INCOMING_ORDERS_APPEND_DATA,
	INCOMING_ORDERS_RELOAD_DATA,
	INCOMING_ORDERS_FILTER_CHANGE,
	INCOMING_ORDERS_PACKET_SELECT,
	INCOMING_ORDERS_REMOVE_PACKETS,
	INCOMING_ORDERS_EDIT_DIMENSION,
	INCOMING_ORDERS_AWB_ITEM_SELECT,
	INCOMING_ORDERS_UNRECEIVED_AWBS,
	INCOMING_ORDERS_ISSUE_INFO_TICK,
	INCOMING_ORDERS_CHECKED_TO_KEEP,
	INCOMING_ORDERS_CLEAR_DIMENSION,
	INCOMING_ORDERS_AWB_WEIGHT_ENTRY,
	INCOMING_ORDERS_AUTO_RELOAD_DATA,
	INCOMING_ORDERS_CLEAR_ISSUE_INFO,
	INCOMING_ORDERS_SCAN_PACKET_ITEM,
	INCOMING_ORDERS_ISSUE_INFO_UPDATE,
	INCOMING_ORDERS_DELAYED_AWB_SELECT,
	INCOMING_ORDERS_SET_VARIANT_DIMENSION,
	INCOMING_ORDERS_PENDING_PACKET_SELECT,
} from "~/actions/types.js";

const INITIAL_STATE = {
	datalist: null,
	page_type: "incoming_awbs",
	page_no: 1,
	page_size: 100,
	search: "",
	sort_by: "",
	filter: "",
	delayed_awbId: "",
	checked_keep_items: false,
	awb_info: [],
	packet_info: [],
	issue_info: [],
	notfound_packets: [],
	pending_packets: [],
	unreceived_awbs: [],
	variant_dimensions: {},
	show_loader: false,
	lost_reason: ""
};

export const IncomingOrdersReducer = (state = INITIAL_STATE, action) => {
	let newState = Object.assign({}, state);

	if (action.type === INCOMING_ORDERS_RELOAD_DATA) {
		newState.datalist = JSON.parse(action.data);
		newState.page_type = action.pageType;
		newState.awb_info = [];
		newState.packet_info = [];
		newState.notfound_packets = [];
		newState.pending_packets = [];
		newState.issue_info = [];
		newState.page_no = 1;
		newState.checked_keep_items = false;
		newState.delayed_awbId = "";
		newState.lost_reason = "";
		newState.show_loader = false;
		newState.variant_dimensions = {};

		return newState;
	}

	if (action.type === INCOMING_ORDERS_AUTO_RELOAD_DATA) {
		newState.datalist = JSON.parse(action.data);

		return newState;
	}

	if(action.type === INCOMING_ORDERS_RESET_DATA){
		return INITIAL_STATE;
	}

	if (action.type === INCOMING_ORDERS_SEARCH) {
		newState.search = action.data;

		return newState;
	}

	if (action.type === INCOMING_ORDERS_FILTER_CHANGE) {
		newState.filter = action.data;

		return newState;
	}

	if (action.type === INCOMING_ORDERS_APPEND_DATA) {
		const jsonData = JSON.parse(action.data);
		//newState.datalist = JSON.parse(JSON.stringify(state.datalist));
		if (newState.page_type == "incoming_awbs") {
			newState.datalist = { ...state.datalist, awblist: state.datalist.awblist.concat(jsonData.awblist) };
		} else if (newState.page_type == "receive_packets") {
			newState.datalist = { ...state.datalist, packetlist: state.datalist.packetlist.concat(jsonData.packetlist) };
		} else {
			newState.datalist = { ...state.datalist, issueitemlist: state.datalist.issueitemlist.concat(jsonData.issueitemlist) };
		}
		newState.page_no += 1;

		return newState;
	}

	if (action.type === INCOMING_ORDERS_CLEAR_ISSUE_INFO) {
		newState.issue_info = [];

		return newState;
	}

	if (action.type === INCOMING_ORDERS_AWB_ITEM_SELECT) {
		let awb = state.awb_info.filter((awbInfo) => awbInfo.awb_id == action.data);
		if (awb.length > 0) newState.awb_info = state.awb_info.filter((awb_info) => awb_info.awb_id != action.data);
		else newState.awb_info = [...state.awb_info, { awb_id: action.data, weight: 0, reason: "" }];

		return newState;
	}

	if (action.type === INCOMING_ORDERS_AWB_WEIGHT_ENTRY) {
		newState.awb_info = state.awb_info.map((awbItem) => (awbItem.awb_id == action.awb_id ? { ...awbItem, weight: action.weight } : awbItem));

		return newState;
	}

	if (action.type === INCOMING_ORDERS_ISSUE_INFO_TICK) {
		const issueItem = state.issue_info.find((item) => item.order_item_id == action.id);
		if (!issueItem) {
			newState.issue_info = [...state.issue_info, { order_item_id: action.id, order_packet_id: action.packet_id, reason: 0, text: "", qty: 0 }];
		} else {
			newState.issue_info = state.issue_info.filter((issue) => issue.order_item_id !== issueItem.order_item_id);
		}
		// else newState.issue_info.splice(newState.issue_info.indexOf(issueItems[0]), 1);

		return newState;
	}

	if (action.type === INCOMING_ORDERS_ISSUE_INFO_UPDATE) {
		newState.issue_info = state.issue_info.map((item) =>
			item.order_item_id == action.id ? { ...item, qty: action.qty, text: action.text, reason: action.reason } : item
		);

		return newState;
	}

	if (action.type === INCOMING_ORDERS_PACKET_SELECT) {
		if (state.packet_info.indexOf(action.data) === -1) newState.packet_info = [...newState.packet_info, action.data];
		else newState.packet_info = state.packet_info.filter((item) => item !== action.data);
		//else newState.packet_info.splice(newState.packet_info.indexOf(action.data), 1);

		return newState;
	}

	if (action.type === INCOMING_ORDERS_PENDING_PACKET_SELECT) {
		if (state.pending_packets.indexOf(action.data) === -1) newState.pending_packets = [...state.pending_packets, action.data];
		else newState.pending_packets = state.pending_packets.filter((item) => item !== action.data);
		//else newState.pending_packets.splice(newState.pending_packets.indexOf(action.data), 1);

		return newState;
	}

	if (action.type === INCOMING_ORDERS_SCAN_PACKET_ITEM) {
		let packet = state.datalist.packetlist.filter((item) => item.packet_id == action.data);

		if (packet.length > 0 && !packet[0].pending_packet) {
			if (newState.packet_info.indexOf(action.data) === -1) newState.packet_info = [action.data, ...state.packet_info];
		} else if (packet.length > 0 && packet[0].pending_packet) {
			if (newState.pending_packets.indexOf(action.data) === -1) newState.pending_packets = [action.data, ...state.pending_packets];
		} else {
			if (newState.notfound_packets.indexOf(action.data) === -1) newState.notfound_packets = [action.data, ...state.notfound_packets];
		}

		return newState;
	}

	if (action.type === INCOMING_ORDERS_REMOVE_PACKETS) {
		if (action.remfrom == 1) newState.packet_info = state.packet_info.filter((item) => item !== action.data);
		//newState.packet_info.splice(newState.packet_info.indexOf(action.data), 1);
		else if (action.remfrom == 2) newState.notfound_packets = state.notfound_packets.filter((item) => item !== action.data);
		//.splice(newState.notfound_packets.indexOf(action.data), 1);
		else if (action.remfrom == 3) newState.pending_packets = state.pending_packets.filter((item) => item !== action.data);
		//newState.pending_packets.splice(newState.pending_packets.indexOf(action.data), 1);

		return newState;
	}

	if (action.type === INCOMING_ORDERS_EDIT_DIMENSION) {
		const item = action.data;
		newState.variant_dimensions = {
			variantid: item.variant_id,
			dimensions: { length: item.length, width: item.width, height: item.height, weight: item.weight }
		};

		return newState;
	}

	if (action.type === INCOMING_ORDERS_SET_VARIANT_DIMENSION) {
		newState.variant_dimensions = {
			...state.variant_dimensions,
			dimensions: { ...state.variant_dimensions.dimensions, [action.name]: action.value }
		};
		//newState.variant_dimensions.dimensions[action.name] = action.value;

		return newState;
	}

	if (action.type === INCOMING_ORDERS_CLEAR_DIMENSION) {
		newState.variant_dimensions = {};

		return newState;
	}

	if (action.type === INCOMING_ORDERS_CHECKED_TO_KEEP) {
		newState.checked_keep_items = !state.checked_keep_items;

		return newState;
	}

	if (action.type === INCOMING_ORDERS_UNRECEIVED_AWBS) {
		newState.unreceived_awbs = action.data;

		return newState;
	}

	if (action.type === INCOMING_ORDERS_DELAYED_AWB_SELECT) {
		newState.delayed_awbId = action.data;

		return newState;
	}

	if (action.type === COMMON_SET_SHOW_LOADER) {
		newState.show_loader = !state.show_loader;

		return newState;
	}

	return state;
};
