import { useEffect } from "react";
import { connect } from "react-redux";

import Footer from "~/components/common/footer.jsx";
import Spinner from "~/components/common/spinner.jsx";
import LeftMenu from "~/components/common/left-menu.jsx";
import Connection from "~/components/common/connection.jsx";

import { useHealthmugLoader } from "~/hooks/healthmug-loader";

import { LoginGetUserDetails } from "~/actions/action-login";

const AppContainer = ({ children, ...props }) => {
	const [loader, startLoader, endLoader] = useHealthmugLoader(true);

	useEffect(() => {
		startLoader();
		props.Login_Get_User_Details().then(endLoader);
	}, []);

	return (
		<>
			<Connection />
			{loader ? (
				<div style={{ textAlign: "center", marginTop: "20%" }}>
					<Spinner primary />
					<h4 style={{ fontSize: "13px", color: "#666" }}>Loading...</h4>
				</div>
			) : (
				<div className={props.logged_in_success ? "wrapper" : null}>
					{props.logged_in_success ? <LeftMenu /> : null}
					{children}
				</div>
			)}
			{props.logged_in_success ? <Footer /> : null}
		</>
	);
};

const mapStateToProps = (state) => ({
	logged_in_success: state.login_store.datalist.logged_in_success ?? false
});
const mapDispatchToProps = (dispatch) => ({
	Login_Get_User_Details: () => dispatch(LoginGetUserDetails())
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
