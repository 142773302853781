import {
	STORES_SEARCH,
	STORES_MARK_LOST,
	STORES_RESET_DATA,
	STORES_ITEM_INFO,
	STORES_RELOAD_DATA,
	STORES_APPEND_DATA,
	STORES_CANCEL_LOST,
	STORES_PACKET_CHANGE,
	STORES_EDIT_DIMENSIONS,
	COMMON_SET_SHOW_LOADER,
	STORES_SET_LOST_REASON,
	STORES_CLEAR_DIMENSIONS,
	STORES_SET_VARIANT_DIMENSION
} from "~/actions/types";

const INITIAL_STATE = {
	datalist: null,
	pagetype: "order_store",
	pageno: 1,
	pagesize: 100,
	search: "",
	iteminfo: [],
	packet_info: [],
	lost: false,
	variant_dimensions: {},
	lost_reason: "",
	show_loader: false
};

export const StoresReducer = (state = INITIAL_STATE, action) => {
	let newState = { ...state, datalist: { ...state.datalist } };

	if (action.type == STORES_RELOAD_DATA) {
		newState.datalist = JSON.parse(action.data);
		newState.pagetype = action.pagetype;
		newState.packet_info = [];
		newState.awb_info = [];
		newState.lost = false;
		newState.pageno = 1;
		newState.show_loader = false;
		newState.lost_reason = "";
		newState.lost = false;

		return newState;
	}

	if (action.type == STORES_RESET_DATA) {
		return INITIAL_STATE;
	}

	if (action.type == STORES_APPEND_DATA) {
		let jsondata = JSON.parse(action.data);
		newState.datalist.storelist = newState.datalist.storelist.concat(jsondata.storelist);
		newState.pageno += 1;

		return newState;
	}

	if (action.type == STORES_SEARCH) {
		newState.search = action.data;

		return newState;
	}

	if (action.type == STORES_ITEM_INFO) {
		newState.iteminfo = JSON.parse(action.data);
		newState.issueinfo = [];

		return newState;
	}

	if (action.type == STORES_PACKET_CHANGE) {
		if (newState.packet_info.indexOf(action.data) == -1) newState.packet_info = [...newState.packet_info, action.data];
		else newState.packet_info = newState.packet_info.filter((item) => item !== action.data);

		return newState;
	}

	if (action.type == STORES_MARK_LOST) {
		newState.lost = action.data;

		return newState;
	}

	if (action.type == STORES_CANCEL_LOST) {
		newState.lost = action.data;
		newState.packet_info = [];

		return newState;
	}

	if (action.type == STORES_CLEAR_DIMENSIONS) {
		newState.variant_dimensions = {};

		return newState;
	}

	if (action.type == STORES_EDIT_DIMENSIONS) {
		var item = action.data;
		newState.variant_dimensions = {
			variantid: item.variant_id,
			dimensions: { length: item.length, width: item.width, height: item.height, weight: item.weight }
		};

		return newState;
	}

	if (action.type == STORES_SET_VARIANT_DIMENSION) {
		newState.variant_dimensions = {
			...newState.variant_dimensions,
			dimensions: { ...newState.variant_dimensions.dimensions, [action.name]: action.value }
		};

		return newState;
	}

	if (action.type == STORES_SET_LOST_REASON) {
		newState.lost_reason = action.value;

		return newState;
	}

	if (action.type == COMMON_SET_SHOW_LOADER) {
		newState.show_loader = action.value;

		return newState;
	}

	return state;
};
