import { connect } from "react-redux";
import { Switch, Route, useLocation } from "wouter";
import loadable from "@loadable/component";

import RouteErrorBoundary from "~/components/common/route-error-boundary.jsx";

const PageNotFoundScreen = loadable(() => import(/* webpackChunkName: "404" */ "~/screens/error/404.jsx"));
const LoginScreen = loadable(() => import(/* webpackChunkName: "login-screen" */ "~/screens/login-screen.jsx"));
const StoresScreen = loadable(() => import(/* webpackChunkName: "stores-screen" */ "~/screens/stores-screen.jsx"));
const OrdersScreen = loadable(() => import(/* webpackChunkName: "orders-screen" */ "~/screens/orders/orders-screen.jsx"));
const IssuesScreen = loadable(() => import(/* webpackChunkName: "issues-screen" */ "~/screens/issues/issues-screen.jsx"));
const SellersScreen = loadable(() => import(/* webpackChunkName: "sellers-screen" */ "~/screens/hub/sellers-screen.jsx"));
const DashboardScreen = loadable(() => import(/* webpackChunkName: "dashboard-screen" */ "~/screens/dashboard-screen.jsx"));
const HubAppsScreen = loadable(() => import(/* webpackChunkName: "hub-apps-screen" */ "~/screens/hub/hub-apps-screen.jsx"));
const HubInfoScreen = loadable(() => import(/* webpackChunkName: "hub-info-screen" */ "~/screens/hub/hub-info-screen.jsx"));
const AnalyticsScreen = loadable(() => import(/* webpackChunkName: "/analytics-screen" */ "~/screens/analytics-screen.jsx"));
const PacketInfoScreen = loadable(() => import(/* webpackChunkName: "packet-info-screen" */ "~/screens/packet-info-screen.jsx"));
const ForgotPassword = loadable(() => import(/* webpackChunkName: "forgot-password" */ "~/components/common/forgot-password.jsx"));
const ContactInfoScreen = loadable(() => import(/* webpackChunkName: "contact-info-screen" */ "~/screens/hub/contact-info-screen.jsx"));
const ChangePasswordScreen = loadable(() => import(/* webpackChunkName: "change-password-screen" */ "~/screens/hub/change-password-screen.jsx"));
const PackagingDashboardScreen = loadable(() =>
	import(/* webpackChunkName: "packaging-dashboard-screen" */ "~/screens/packaging-dashboard-screen.jsx")
);
const IncomingOrdersScreen = loadable(() =>
	import(/* webpackChunkName: "incoming-orders-screen" */ "~/screens/incoming-orders/incoming-orders-screen.jsx")
);
const OutgoingReturnsScreen = loadable(() =>
	import(/* webpackChunkName: "outgoing-returns-screen" */ "~/screens/outgoing-returns/outgoing-returns-screen.jsx")
);
const CancelledReturnsScreen = loadable(() =>
	import(/* webpackChunkName: "cancelled-returns-screen" */ "~/screens/cancelled-returns/cancelled-returns-screen.jsx")
);

const AppRoutes = (props) => {
	const [location, setLocation] = useLocation();

	if (props.logged_in_success && location == "/") {
		setLocation("/analytics/dashboard");
		return;
	}

	return (
		<RouteErrorBoundary>
			<Switch>
				<Route path="/" component={LoginScreen} />
				<Route path="/hub/sellers" component={SellersScreen} />
				<Route path="/issues/issues" component={IssuesScreen} />
				<Route path="/orders/orders" component={OrdersScreen} />
				<Route path="/stores/stores" component={StoresScreen} />
				<Route path="/hub/hub-info" component={HubInfoScreen} />
				<Route path="/hub/hub-apps" component={HubAppsScreen} />
				<Route path="/hub/contact-info" component={ContactInfoScreen} />
				<Route path="/analytics/analytics" component={AnalyticsScreen} />
				<Route path="/orders/packet-info" component={PacketInfoScreen} />
				<Route path="/analytics/dashboard" component={DashboardScreen} />
				<Route path="/home/forgot-password" component={ForgotPassword} />
				<Route path="/hub/change-password" component={ChangePasswordScreen} />
				<Route path="/orders/incoming-orders" component={IncomingOrdersScreen} />
				<Route path="/orders/outgoing-returns" component={OutgoingReturnsScreen} />
				<Route path="/orders/cancelled-returns-orders" component={CancelledReturnsScreen} />
				<Route path="/analytics/packaging-dashboard" component={PackagingDashboardScreen} />

				<Route component={PageNotFoundScreen} />
			</Switch>
		</RouteErrorBoundary>
	);
};

const mapStateToProps = (state) => ({
	logged_in_success: state.login_store.datalist.logged_in_success
});

export default connect(mapStateToProps)(AppRoutes);
