import { memo } from "react";

const Spinner = ({ micro, small, large, primary, warning, danger, success, light, className, style }) => {
	let icon_style = { display: "inline-block" };

	if (micro) {
		icon_style = { ...icon_style, width: "8px", height: "8px" };
	} else if (small) {
		icon_style = { ...icon_style, width: "16px", height: "16px" };
	} else if (large) {
		icon_style = { ...icon_style, width: "32px", height: "32px" };
	} else {
		icon_style = { ...icon_style, width: "24px", height: "24px" };
	}

	if (primary) {
		icon_style = { ...icon_style, fill: "#16c0cc" };
	} else if (warning) {
		icon_style = { ...icon_style, fill: "#fb8903" };
	} else if (success) {
		icon_style = { ...icon_style, fill: "#5cb85c" };
	} else if (danger) {
		icon_style = { ...icon_style, fill: "#c9302c" };
	} else if (light) {
		icon_style = { ...icon_style, fill: "#fff" };
	} else {
		icon_style = { ...icon_style, fill: "#000" };
	}

	if (!!style) {
		icon_style = { ...icon_style, ...style };
	}

	return (
		<span className="loader inline-block">
			<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" className={className} style={icon_style}>
				<circle fill="none" cx="15" cy="15" r="14" />
			</svg>
		</span>
	);
};

export default memo(Spinner);
