import {
	ORDERS_SEARCH,
	ORDERS_BOX_SIZE,
	ORDERS_ITEM_INFO,
	ORDERS_EDIT_ORDER,
	ORDERS_RESET_DATA,
	ORDERS_RELOAD_DATA,
	ORDERS_APPEND_DATA,
	ORDERS_ORDER_CHANGE,
	ORDERS_SCAN_AWB_ITEM,
	ORDERS_PACKET_CHANGE,
	ORDERS_REMOVE_ORDERS,
	ORDERS_FILTER_CHANGE,
	ORDERS_AWB_ITEM_CHANGE,
	ORDERS_ISSUE_INFO_TICK,
	ORDERS_SCAN_ORDER_ITEM,
	ORDERS_CHECKED_TO_KEEP,
	ORDERS_VIEW_PICKUP_DATA,
	ORDERS_ISSUE_INFO_UPDATE,
	ORDERS_SET_PARCEL_DIMENSION,
	ORDERS_FILTER__WEBSITE_CHANGE
} from "~/actions/types";

const INITIAL_STATE = {
	datalist: {},
	order_list: [],
	page_type: "to_manifest",
	page_no: 1,
	search: "",
	sort_by: "",
	filter: "",
	website_filter: "",
	box_size: "s0",
	page_size: 100,
	manifest_order: "",
	selected_packet_qty: 0,
	checked_keep_items: false,
	awb_info: [],
	item_info: [],
	order_info: [],
	issue_info: [],
	pickup_data: [],
	packet_info: [],
	parcel_array: [],
	notfound_orders: []
};

export const OrdersReducer = (state = INITIAL_STATE, action) => {
	let newState = Object.assign({}, state);

	if (action.type === ORDERS_RELOAD_DATA) {
		newState.datalist = JSON.parse(action.data);
		newState.page_no = 1;
		newState.manifest_order = "";
		newState.filter = action.filter;
		newState.checked_keep_items = false;
		newState.page_type = action.pageType;
		newState.awb_info = [];
		newState.order_info = [];
		newState.issue_info = [];
		newState.packet_info = [];
		newState.parcel_array = [];
		if (newState.page_type == "to_manifest") {
			const order_list = JSON.parse(action.data).orderlist;
			newState.order_list = order_list.length >= newState.page_size ? order_list.slice(0, newState.page_size) : order_list;
		}

		return newState;
	}

	if (action.type === ORDERS_RESET_DATA) {
		return INITIAL_STATE;
	}
	if (action.type === ORDERS_SEARCH) {
		newState.search = action.data;

		return newState;
	}

	if (action.type === ORDERS_APPEND_DATA) {
		if (state.page_type === "to_manifest") {
			newState.order_list = newState.order_list.concat(action.data);
		} else {
			newState.datalist = JSON.parse(JSON.stringify(state.datalist));
			if (state.page_type === "upcoming") newState.datalist.orderlist = newState.datalist.orderlist.concat(JSON.parse(action.data).orderlist);
			else newState.datalist.awblist = newState.datalist.awblist.concat(JSON.parse(action.data).awblist);
		}
		newState.page_no += 1;

		return newState;
	}

	if (action.type === ORDERS_AWB_ITEM_CHANGE) {
		if (newState.awb_info.indexOf(action.data) === -1) {
			newState.awb_info = [...newState.awb_info, action.data];
			const orderId = newState.datalist.awblist.find((item) => item.awb_id === action.data).order_id;
			const awb_parcel = newState.datalist.awblist.find((item) => item.awb_id === action.data);
			newState.parcel_array = [
				...newState.parcel_array,
				awb_parcel.delivery_type == 1
					? {
							awb_id: action.data,
							awb: awb_parcel.awb,
							order_id: orderId,
							dimensions: { length: "", width: "", height: "", weight: "", packaging_weight: "" }
					  }
					: {
							awb_id: action.data,
							awb: awb_parcel.awb,
							order_id: orderId,
							dimensions: { length: "1", width: "1", height: "1", weight: "", packaging_weight: "" }
					  }
			];
		} else {
			newState.awb_info = newState.awb_info.filter((item) => item !== action.data);
			newState.parcel_array = newState.parcel_array.filter((item) => item.awb_id != action.data);
		}

		return newState;
	}

	if (action.type === ORDERS_SCAN_AWB_ITEM) {
		if (newState.awb_info.indexOf(action.data) === -1) {
			newState.awb_info = [...newState.awb_info, action.data];
			const orderId = newState.datalist.awbs.filter((item) => item.awb_id === action.data)[0].order_id;
			const awb_data = newState.datalist.awbs.filter((item) => item.awb_id === action.data)[0];
			const awb = awb_data.awb;
			const total_item_weight = awb_data.total_item_weight;
			let length = "";
			let width = "";
			let height = "";
			let packaging_weight = "";
			if (awb_data.delivery_type == 3) {
				length = "1";
				width = "1";
				height = "1";
			} else {
				if (newState.box_size === "s0") {
					packaging_weight = total_item_weight * 0.1;
				}
				if (newState.box_size === "s1") {
					length = 15.5;
					width = 7.8;
					height = 10.2;
					packaging_weight = 90;
				}
				if (newState.box_size === "s2") {
					length = 19.2;
					width = 13;
					height = 10;
					packaging_weight = 160;
				}
				if (newState.box_size === "s3") {
					length = 23.5;
					width = 16.5;
					height = 12;
					packaging_weight = 360;
				}
				if (newState.box_size === "s4") {
					length = 28;
					width = 18.5;
					height = 18.5;
					packaging_weight = 500;
				}
				if (newState.box_size === "s5") {
					length = 31;
					width = 21.5;
					height = 21.5;
					packaging_weight = 800;
				}
			}
			newState.parcel_array = [
				{
					awb_id: action.data,
					awb: awb,
					order_id: orderId,
					dimensions: { length: length, width: width, height: height, theoretical_weight: total_item_weight + packaging_weight, weight: "" }
				},
				...newState.parcel_array
			];
		}

		return newState;
	}

	if (action.type === ORDERS_SET_PARCEL_DIMENSION) {
		newState.parcel_array = newState.parcel_array.map((parcel) =>
			parcel.awb_id === action.awbId ? { ...parcel, dimensions: { ...parcel.dimensions, [action.dimension]: action.value } } : parcel
		);

		return newState;
	}

	if (action.type === ORDERS_BOX_SIZE) {
		newState.box_size = action.size;

		return newState;
	}

	if (action.type === ORDERS_ITEM_INFO) {
		newState.item_info = JSON.parse(action.data);
		newState.issue_info = [];

		return newState;
	}

	if (action.type === ORDERS_ISSUE_INFO_TICK) {
		const issueItem = newState.issue_info.filter((item) => item.order_item_id === action.id);
		const firstIssueItem = issueItem[0];
		if (issueItem.length === 0) {
			newState.issue_info = [
				...newState.issue_info,
				{ order_item_id: action.id, order_packet_id: action.packetId, reason: 0, text: "", qty: 0 }
			];
		} else {
			newState.issue_info = newState.issue_info.filter((item) => item !== firstIssueItem);
		}

		return newState;
	}

	if (action.type === ORDERS_ISSUE_INFO_UPDATE) {
		newState.issue_info = newState.issue_info.map((issue) =>
			issue.order_item_id === action.id ? { ...issue, qty: action.qty, text: action.text, reason: action.reason } : issue
		);

		return newState;
	}

	if (action.type === ORDERS_ORDER_CHANGE) {
		let index = newState.order_info.findIndex((item) => item.order_id == action.data && item.delivery_type == action.delivery_type);
		if (index === -1) {
			newState.order_info = [{ order_id: action.data, delivery_type: action.delivery_type }, ...newState.order_info];
		} else {
			newState.order_info = newState.order_info.filter((item) => !(item.order_id == action.data && item.delivery_type == action.delivery_type));
		}
		return newState;
	}

	if (action.type === ORDERS_EDIT_ORDER) {
		newState.packet_info = [];
		if (newState.manifest_order === action.data) newState.manifest_order = "";
		else newState.manifest_order = action.data;

		return newState;
	}

	if (action.type === ORDERS_PACKET_CHANGE) {
		if (newState.packet_info.indexOf(action.data) === -1) newState.packet_info = [...newState.packet_info, action.data];
		else newState.packet_info = newState.packet_info.filter((item) => item !== action.data);
		let totalPacketQty = 0;
		for (let i = 0; i < newState.packet_info.length; i++) {
			let qty = 0;
			newState.datalist.orderlist.find((order) => {
				return order.itemlist.find((packet) => {
					if (packet.packet_id === newState.packet_info[i]) {
						qty = packet.qty;
						return true;
					}
				});
			});
			totalPacketQty += qty;
		}
		newState.selected_packet_qty = totalPacketQty;

		return newState;
	}

	if (action.type === ORDERS_SCAN_ORDER_ITEM) {
		let found_index = newState.order_info.findIndex((item) => item.order_id == action.data && item.delivery_type == action.delivery_type);
		let not_found_index = newState.order_info.findIndex((item) => item.order_id == action.data && item.delivery_type == action.delivery_type);

		if (newState.datalist.orderlist.filter((item) => item.order_id === action.data && item.delivery_type == action.delivery_type).length > 0) {
			if (found_index === -1) newState.order_info = [{ order_id: action.data, delivery_type: action.delivery_type }, ...newState.order_info];
		} else {
			if (not_found_index === -1) {
				newState.notfound_orders = [{ order_id: action.data, delivery_type: action.delivery_type }, ...newState.notfound_orders];
			}
		}

		return newState;
	}

	if (action.type === ORDERS_REMOVE_ORDERS) {
		if (action.removeFrom === 1) {
			newState.order_info = newState.order_info.filter((item) => !(item.order_id == action.data && item.delivery_type == action.delivery_type));
		} else if (action.removeFrom === 2) {
			newState.notfound_orders = newState.notfound_orders.filter(
				(item) => !(item.order_id == action.data && item.delivery_type == action.delivery_type)
			);
		}

		return newState;
	}

	if (action.type === ORDERS_CHECKED_TO_KEEP) {
		newState.checked_keep_items = !state.checked_keep_items;

		return newState;
	}

	if (action.type === ORDERS_FILTER_CHANGE) {
		newState.filter = action.value;

		return newState;
	}

	if (action.type == ORDERS_FILTER__WEBSITE_CHANGE) {
		newState.website_filter = action.value;

		return newState;
	}

	if (action.type === ORDERS_VIEW_PICKUP_DATA) {
		newState.pickup_data = JSON.parse(action.data);

		return newState;
	}

	return state;
};
