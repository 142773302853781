import { PACKET_INFO_RELOAD_DATA, PACKET_INFO_TRACK_ITEM } from "~/actions/types";

const INITIAL_STATE = {
	datalist: {},
	awb_number: "",
	selected_packet: "",
	track_info: [],
	packet_list: []
};

export const PacketInfoReducer = (state = INITIAL_STATE, action) => {
	let newState = Object.assign({}, state);

	if (action.type == PACKET_INFO_RELOAD_DATA) {
		const data = JSON.parse(action.data);
		newState.datalist = data;
		newState.selected_packet = data.packet.packet_id;
		if (action.name != "selected_packet") {
			newState.packet_list = data.packet_list;
		}

		return newState;
	}

	if (action.type == PACKET_INFO_TRACK_ITEM) {
		const data = JSON.parse(action.data);
		newState.track_info = data.tracking;
		newState.awb_number = data.awb;

		return newState;
	}

	return state;
};
